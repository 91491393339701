import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
  flexBasis: 80,
  minHeight: 80,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 6,
  position: 'fixed',
  backgroundColor: '#fff',
  width: '100%',
  zIndex: 999,
  borderBottom: '1px solid #dddddd',
}
