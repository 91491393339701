import { Box, Typography } from '@mui/material'
import { rootSx } from './style'
import { useEffect, useRef, useState } from 'react'
import { routerPush } from '@/lib/urls'
import { useRouter } from 'next/router'
type Props = {
  title: string
  href: string
  onHoverTitle: (title?: string) => void
  onMouseHover: (hover?: boolean) => void
}
export default function MyTabItem(props: Props) {
  const { title, href, onHoverTitle, onMouseHover } = props
  const [btnHover, setBtnHover] = useState(false)
  const router = useRouter()
  const onMouseHoverFnRef = useRef<Props['onMouseHover']>()
  onMouseHoverFnRef.current = onMouseHover

  useEffect(() => {
    onMouseHoverFnRef.current?.(btnHover)
  }, [btnHover])

  const currentUrl = href === router.pathname
  return (
    <Box sx={rootSx} onClick={() => routerPush(href)}>
      <Typography
        color={btnHover || currentUrl ? '#00a79d' : 'black'}
        // color={btnHover ? '#e9aa20' : 'black'}
        flex={1}
        onMouseLeave={() => {
          setBtnHover(false)
        }}
        onMouseEnter={() => {
          setBtnHover(true)
          onHoverTitle(title)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: '600',
          fontSize: '1.1rem',
          px: 2.5,
          transition: '0.5s',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{ transform: btnHover || currentUrl ? 'scale(1)' : 'scale(0)' }}
        className={'basic-btn'}
      />
    </Box>
  )
}
