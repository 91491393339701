import HomePageLayout from '@/layouts/HomPageLayout/HomePageTopBar/HomePageLayout'
import type { NextPage } from 'next'
import dynamic from 'next/dynamic'

const HomePage = dynamic(() => import('@/features/HomePageCLMain'))
const CloverMain: NextPage = () => {
  return (
    <HomePageLayout>
      <HomePage />
    </HomePageLayout>
  )
}

export default CloverMain
