interface MenuType {
  id: number
  title: string
  href: string
  subMenu?: subMenu[]
}
interface subMenu {
  title: string
  href: string
}
export const SUB_MENU_LIST: MenuType[] = [
  {
    id: 0,
    title: 'CL',
    href: '/',
    subMenu: [
      { title: 'CL 소개', href: '/' },
      { title: '사우회 회원', href: '/homepages/homepage-user-mgmt' },
      { title: '신규입사자 소개', href: '/homepages/homepage-empl' },
    ],
  },
  {
    id: 1,
    title: '동호회',
    href: '/homepages/homepage-club',
    subMenu: [
      { title: '동호회 소개', href: '/homepages/homepage-club' },
      { title: '동호회 회원', href: '/homepages/homepage-user-mgmt' },
    ],
  },
  {
    id: 2,
    title: '게시판',
    href: '/homepages/homepage-board',
  },
  {
    id: 3,
    title: '복리후생',
    href: '/',
    subMenu: [
      { title: '직원 복지', href: '/homepages/homepage-company-welfare' },
      { title: '별장 안내', href: '/homepages/homepage-villa' },
      { title: '별장 예약', href: '/homepages/homepage-villa-reservation' },
      { title: '예약 현황', href: '/homepages/homepage-villa-reserve-report' },
      { title: '영수증 청구', href: '/homepages/homepage-bill-payment' },
      // { title: '당첨자 추첨', href: '/homepages/homepage-villa-raffle' },
    ],
  },
  {
    id: 4,
    title: '채용',
    href: '/',
    subMenu: [{ title: '프로젝트 목록', href: '/homepages/homepage-project' }],
  },
]
