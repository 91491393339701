import { useLoginProfile } from '@/auth/useLoginProfile'
import { LoginFormDialogProps } from '@/dialogs/LoginFormDialog'
import LoginFormDialog from '@/dialogs/LoginFormDialog/LoginFormDialog'
import { routerPush } from '@/lib/urls'
import { Box, Button, ButtonGroup, Stack, Typography, keyframes } from '@mui/material'
import { useState } from 'react'
import { SUB_MENU_LIST } from '../../all-top-menu'
import MyTabItem from '../MyTabItem/MyTabItem'
import TopProfileBox from '../TopProfileBox/TopProfileBox'
import { rootSx } from './style'
type DialogId = 'LoginFormDialog'
export default function HomePageTopbar() {
  const [hoverTitle, setHoverTitle] = useState<string>()
  const [mouseHover, setMouseHover] = useState(false)
  const [subHoverArea, setSubHoverArea] = useState(false)
  const loginProfile = useLoginProfile()
  const isLoggedIn = !!loginProfile
  const text = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `
  const [dialogId, setDialogId] = useState<DialogId>()
  const [loginFormDialogProps, setLoginFormDialogProps] = useState<LoginFormDialogProps>()
  const openLoginDialog = () => {
    setDialogId('LoginFormDialog')
    setLoginFormDialogProps({
      open: true,
      onClose: closeDialog,
    })
  }
  const closeDialog = () => {
    setDialogId(undefined)
    setLoginFormDialogProps(undefined)
  }
  return (
    <Box sx={rootSx}>
      <Stack>
        <Box
          onClick={() => {
            routerPush('/')
          }}
          component="img"
          src="/images/clover-text-logo.png"
          sx={{ cursor: 'pointer', height: 40, alignSelf: 'center' }}
        />
      </Stack>
      <Stack direction="row" alignItems="stretch" sx={{ height: 80 }} spacing={7}>
        {SUB_MENU_LIST.map((el) => (
          <MyTabItem
            onMouseHover={(hover) => {
              if (hover === false) {
                setMouseHover(hover)
              } else if (el.subMenu === undefined) {
                setSubHoverArea(false)
              } else {
                setSubHoverArea(true)
              }
            }}
            onHoverTitle={(title) => {
              setHoverTitle(title)
            }}
            title={el.title}
            href={el.href}
            key={el.id}
          />
        ))}
      </Stack>
      {isLoggedIn ? (
        <TopProfileBox profile={loginProfile.profile} />
      ) : (
        <ButtonGroup
          color="inherit"
          size="small"
          sx={{
            height: 20,
            '& .MuiButtonBase-root': {
              fontWeight: 700,
            },
          }}
          variant="text"
        >
          <Button sx={{ pr: 1 }} onClick={openLoginDialog}>
            로그인
          </Button>
          <Button sx={{ pl: 1 }}>회원가입</Button>
        </ButtonGroup>
      )}

      <Box
        className="HomePageLayout-subBox"
        sx={{
          height: 60,
          transform: mouseHover || subHoverArea ? 'scaleY(1)' : 'scaleY(0)',
          transformOrigin: 'top',
          transition: 'transform 0.4s ease-in-out',
          borderTop: '1px solid #eee',
          position: 'absolute',
          zIndex: 999,
          top: 80,
          left: -1,
          backgroundColor: '#fff',
          width: '100vw',
          boxShadow: '0px 4px 4px -4px #000',
        }}
        onMouseLeave={() => {
          setSubHoverArea(false)
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ height: '100%', mx: 2 }}
        >
          {SUB_MENU_LIST.map((el) => {
            if (el.title === hoverTitle) {
              return el.subMenu?.map((sub) => (
                <Box onClick={() => routerPush(sub.href)} key={sub.title}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      animation: `${text} 0.5s steps(30, end) forwards`,
                      fontSize: '1.0rem',
                      display: mouseHover || subHoverArea ? 'block' : 'none',
                      '&:hover': {
                        color: '#00a79d',
                        cursor: 'pointer',
                      },
                    }}
                    color="#717171"
                  >
                    {sub.title}
                  </Typography>
                </Box>
              ))
            }
            return null
          })}
        </Stack>
      </Box>
      {dialogId === 'LoginFormDialog' && loginFormDialogProps && (
        <LoginFormDialog {...loginFormDialogProps} />
      )}
    </Box>
  )
}
