import { Box } from '@mui/material'
import HomePageTopbar from './components/HomePageTopbar/HomePageTopbar'

interface Props {
  children?: React.ReactNode
}

export default function HomePageLayout(props: Props) {
  const { children } = props
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HomePageTopbar />
      <Box mt={10} sx={{ flex: 1, overflow: 'auto' }}>
        {children}
      </Box>
    </Box>
  )
}
