import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
  cursor: 'pointer',
  '& .basic-btn': {
    border: '2px solid #00a79d',
    transformOrigin: 'center',
    transition: 'transform 0.5s ease-in-out',
  },
}
