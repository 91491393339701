import { UserProfile } from '@local/domain'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined'
import {
  Avatar,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'
import { useRouter } from 'next/router'
import { useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import { useLoginProfile } from '@/auth/useLoginProfile'
const rootSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
}

type Props = {
  profile: UserProfile
}

export default function TopProfileBox(props: Props) {
  const { lgonId, userId, userNm } = props.profile
  const router = useRouter()
  const [popOverHandle, setPopOverHandle] = useState<HTMLButtonElement | null>(null)
  const loginProfile = useLoginProfile()
  // const isLoggedIn = !!loginProfile

  const handleClickPop = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopOverHandle(event.currentTarget)
  }
  const handleClosePop = () => {
    setPopOverHandle(null)
  }

  const onClickLogout = () => {
    router.push('/logout')
  }
  return (
    <Stack sx={rootSx} className="TopProfileBox-root" direction="row">
      <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={1}>
        <Avatar sx={{ fontSize: '0.3rem', width: 35, height: 35, backgroundColor: '#00a79d' }}>
          {userNm}
        </Avatar>
        <Typography variant="subtitle1">{lgonId}</Typography>
      </Stack>
      <IconButton color="default" onClick={handleClickPop}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(popOverHandle)}
        anchorEl={popOverHandle}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              onClickLogout()
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            로그아웃
          </MenuItem>
          {loginProfile?.profile.navId === '2' && (
            <MenuItem
              onClick={() => {
                router.push('/admin')
              }}
            >
              <ListItemIcon>
                <AccountCircleOutlinedIcon />
              </ListItemIcon>
              관리자 페이지
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </Stack>
  )
}
